/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout';
import SEO from '../components/seo';

const PrivacyPolicy = ({ data }) => {
  const privacyData = data.neueamsterdam.page;

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="container">
        <div className="privacy-page">
          <div className="scrollable-content__content-wrapper">
            <article className="scrollable-content__contents">
              <h1>{privacyData.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: privacyData.content }} />
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({
    neueamsterdam: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    neueamsterdam {
      page(id: 1526, idType: DATABASE_ID) {
        title
        content
      }
    }
  }
`;

export default PrivacyPolicy;
